import React from 'react'

type Props = {
	links?: React.ReactNode[]
	header: string
	description: string
	rightElement?: React.ReactNode
}

const Hero = (props: Props) => {
	return (
		<div className='bg-cream lg:mb-20 mb-10'>
			<div className='container font-poppins flex items-center justify-between'>
				<div className='md:w-10/12 lg:py-20 py-10'>
					<div className='mb-4'>{props.links}</div>
					<h1 className='text-header font-medium'>{props.header}</h1>
					<div className='text-small text-grey font-merriweather mt-[0.625rem]'>
						{props.description}
					</div>
				</div>
				{props.rightElement}
			</div>
		</div>
	)
}

export default Hero

import React from 'react'
import { Link } from 'gatsby'
import '../styles/learn-more.scss'
import RightArrow from '../images/home/right-arrow.svg'

type Props = {
	imageSrc: React.ReactNode
	header: string
	text: string
	link: string
	className?: string
}

const LearnMoreBox = (props: Props) => {
	return (
		<div
			className={`${
				props.className ? props.className : ''
			} lg:mb-20 mb-6 flex flex-col`}
		>
			<div className='md:mb-4 mb-1'>{props.imageSrc}</div>
			<div className='flex flex-col flex-1'>
				<h2 className='font-poppins text-subhead font-medium lg:h-auto'>
					{props.header}
				</h2>
				<div className='md:mt-2 mt-1 md:mb-4 mb-1 font-merriweather text-small grow'>
					{props.text ? `${props.text?.substring(0, 95)}...` : ''}
				</div>
				<Link
					to={props.link}
					className='hover-link text-navyBlue text-small leading-8'
				>
					<div>Detaylı Bilgi</div>
					<div className='arrow-wrapper flex items-center flex-1'>
						<img src={RightArrow} alt='' className='arrow' />
					</div>
				</Link>
			</div>
		</div>
	)
}

export default LearnMoreBox

import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../templates/layout'
import LearnMoreBox from '../../components/LearnMoreBox'
import Hero from '../../components/Hero'
import { graphql } from 'gatsby'
import SEO from '../../components/SEO'

type Props = {
	data: {
		markdownRemark: {
			frontmatter: {
				slug: string
				title: string
				description: string
			}
		}
		allPrismicCategories: {
			nodes: {
				uid: string
				data: {
					is_in_homepage: boolean
					meta_description: string
					meta_title: string
					name: string
					sort_order: number
					fieldPath: string
					icon: {
						url: string
					}
					content: {
						text: string
					}
				}
			}[]
		}
	}
}

const ActivityFields = ({ data }: Props) => {
	const activityFields = data.allPrismicCategories.nodes
	const metaTags = data.markdownRemark.frontmatter
	return (
		<Layout border={true}>
			<SEO
				title={metaTags.title}
				description={metaTags.description}
				path={metaTags.slug}
			/>
			<Hero
				header='Faaliyet Alanları'
				description='Uzman ekibimiz ile tüm hukuki ihtiyaçlarınız için yanınızdayız. Akla
						ve bilgiye dayanan yöntemlerle müvekkillerimize en güncel hukuki
						çözümleri sunuyoruz.'
				links={[
					<Link to='/' className='mr-6 text-small text-grey' key='home-link'>
						Ana Sayfa
					</Link>,
					<Link
						to='/faaliyet-alanlari'
						className='font-medium text-small text-grey'
						key='activity-fields-link'
					>
						Faaliyet Alanları
					</Link>,
				]}
			/>
			<div className='container lg:pb-20 pb-10'>
				<div className='sm:flex flex-wrap'>
					{activityFields.map((field, f) => {
						return (
							<LearnMoreBox
								key={field.uid}
								className='sm:pr-10 lg:w-1/4 md:w-1/2 w-full'
								imageSrc={<img src={field.data.icon.url} alt='' />}
								header={field.data.name}
								text={field.data.content?.text}
								link={`/${field.uid}`}
							/>
						)
					})}
				</div>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query ActivityFieldsQuery {
		markdownRemark(fileAbsolutePath: { regex: "/categories.md/" }) {
			frontmatter {
				slug
				title
				description
			}
		}
		allPrismicCategories(sort: { fields: data___sort_order }) {
			nodes {
				uid
				data {
					is_in_homepage
					meta_description
					meta_title
					name
					sort_order
					icon {
						url
					}
					content {
						text
					}
				}
			}
		}
	}
`
export default ActivityFields
